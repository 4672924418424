export const phrases = {
  smthWentWrongText: "Что-то пошло не так!",
  smthWentWrong: "Что-то пошло не так! Проверьте данные и повторите попытку",

  login_sucess: "Добро пожаловать",
  send_pwd: "Новый пароль отправлен вам на почту.",

  admin_success_added: "Администратор успешно добавлен",
  admin_sucess_deleted: "Администратор успешно удален",
  admin_success_updated: "Администратор успешно обновлен",

  user_success_added: "Пользователь успешно добавлен",
  user_sucess_deleted: "Пользователь успешно удален",
  user_success_updated: "Пользователь успешно обновлен",
  user_password_success_updated: "Пароль успешно обновлен",
  user_success_blocked: "Пользователь успешно заблокирован",
  user_success_unblocked: "Пользователь успешно разблокирован",
  user_link_turn_on: "Ссылка успешно включена",
  user_link_turn_off: "Ссылка успешно выключена",
  user_second_level_link_turn_on: "Второй уровень ссылки успешно включен",
  user_second_level_link_turn_off: "Второй уровень ссылки успешно отключен",

  link_success_enabled: "Ссылка успешно включена",
  link_success_copy: "Ссылка скопирована в буфер обмена",
  link_success_disabled: "Ссылка успешно отключена",

  answer_success_added: "Ответ успешно добавлен",

  course_success_added: "Курс успешно добавлен",
  course_sucess_deleted: "Курс успешно удален",

  post_success_deleted: "Новость успешно удалена",
  course_sucess_published: "Курс успешно опубликован",
  course_sucess_deactivated: "Курс успешно деактивирован",
  course_sucess_return_for_revision: "Курс успешно возвращен на доработку",
  course_sucess_updated: "Курс успешно обновлен",
  course_sucess_out_to_main_page: "Курс успешно выведен на Главную страницу",
  course_sucess_out_from_main_page: "Курс успешно удален с Главной страницы",
  course_sucess_out_to_top: "Курс успешно выведен в ТОП",
  course_sucess_out_from_top: "Курс успешно выведен из ТОПа",

  module_success_added: "Модуль успешно добавлен",
  module_sucess_deleted: "Модуль успешно удален",
  module_success_updated: "Модуль успешно обновлен",

  lesson_success_added: "Урок успешно добавлен",
  lesson_sucess_deleted: "Урок успешно удален",
  lesson_sucess_updated: "Урок успешно обновлен",

  enter_password: "Введите пароль",
  enter_new_password: "Введите новый пароль",
  enter_confirm_password: "Повторите новый пароль",
  passwords_not_same: "Пароли должны совпадать",
  wrong_password: "Неверный пароль",
  password_success_updated: "Пароль успешно обновлен",

  direction_success_added: "Направление успешно добавлено",
  direction_sucess_deleted: "Направление успешно удалено",
  direction_success_updated: "Направление успешно обновлено",

  service_success_added: "Услуга успешно добавлена",
  service_success_updated: "Услуга успешно обновлена",
  service_success_deleted: "Услуга успешно удалена",

  personal_data_success_updated: "Информация профиля успешно обновлена",
  removed_from_course_success: "Пользователь успешно снят с курса",
  required_field: "Поле обязательно",

  broadcast_success_added: "Трансляция успешно добавлена",
  broadcast_success_updated: "Трансляция успешно обновлена",
  broadcast_success_deleted: "Трансляция успешно удалена",

  invalid_video_url: "Неверный URL видео",

  application_status_updated: "Статус услуги изменен",
};
