import {
  ApplicationsIcon,
  ExitIcon,
  OnboaringStatisticsIcon,
  RefferalIcon,
  SettingsIcon,
  SupportIcon,
} from "src/components/common/icons";
import {
  BookIcon,
  UsersIcon,
  AdminsIcon,
  DirectionsIcon,
  WalletIcon,
  SubscriptionIcon,
  StatisticIcon,
  CancelIcon,
  ProfitIcon,
} from "src/components/common/icons";

const ADMIN_MENU = [
  {
    label: "Пользователи",
    customIcon: <UsersIcon />,
    to: "/users",
  },
  {
    label: "Администраторы",
    customIcon: <AdminsIcon />,
    to: "/admins",
  },
  {
    label: "Курсы",
    customIcon: <BookIcon />,
    to: "/courses",
  },
  {
    label: "Направления",
    customIcon: <DirectionsIcon />,
    to: "/directions",
  },
  {
    label: "Услуги",
    customIcon: <ApplicationsIcon />,
    to: "/applications",
  },
  {
    label: "Продажи",
    customIcon: <WalletIcon />,
    items: [
      {
        label: "Подписки",
        customIcon: <SubscriptionIcon />,
        to: "/subscriptions",
      },
      {
        label: "Статистика",
        customIcon: <StatisticIcon />,
        to: "/payments-statistics",
      },
      {
        label: "Причины отмены",
        customIcon: <CancelIcon />,
        to: "/cancel-reasons",
      },
      {
        label: "Прибыль",
        customIcon: <ProfitIcon />,
        to: "/referral-system",
      },
    ],
  },

  {
    label: "Настройки",
    customIcon: <SettingsIcon />,
    items: [
      { label: "Промо", to: "settings-promo", icon: "pi pi-hashtag", noOnlyOnHide: true },
      { label: "Отзывы", to: "settings-reviews", icon: "pi pi-comment", noOnlyOnHide: true },
      {
        label: "Информация для администраторов",
        to: "/settings-admin-info",
        icon: "pi pi-ellipsis-h",
        noOnlyOnHide: true,
      },
      {
        label: "Заработок",
        to: "/settings-earnings",
        icon: "pi pi-hashtag",
        noOnlyOnHide: true,
      },
    ],
  },
  {
    label: "Рефералы",
    customIcon: <RefferalIcon />,
    to: "/refferal-list",
  },
  {
    label: "Статистика по онбордингу",
    customIcon: <OnboaringStatisticsIcon />,
    to: "/onboarding-statistics",
  },
  {
    label: "Профиль",
    profileImage: true,
    to: "/profile",
    bottom: true,
  },
  {
    label: "Выход",
    customIcon: <ExitIcon />,
    bottom: true,
    logout: true,
  },
];

const CURATOR_SPEAKER_MENU = [
  {
    label: "Профиль",
    profileImage: true,
    to: "/profile",
  },
  {
    label: "Поддержка",
    customIcon: <SupportIcon />,
    to: "/settings-admin-info",
  },
  {
    label: "Заработок",
    customIcon: <ProfitIcon />,
    to: "/earnings",
  },
  {
    label: "Выход",
    customIcon: <ExitIcon />,
    bottom: true,
    logout: true,
  },
];

export const menu = () => {
  const role = localStorage.getItem("role");
  const IS_ADMIN = role === "admin";

  if (IS_ADMIN) return ADMIN_MENU;
  else return CURATOR_SPEAKER_MENU;
};
